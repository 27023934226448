require('./bootstrap');

require('alpinejs');
require('livewire-sortable');



import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();
